<template>
  <div>
    <el-card class="box-card">
      <div class="search-table-box">
        <div>
          <el-form
            ref="form"
            :inline="true"
            :model="queryParams"
            label-width="80px"
          >
            <el-form-item label="经营项目">
              <el-select
                v-model="queryParams.businessItems"
                multiple
                placeholder="请选择经营项目"
              >
                <el-option
                  v-for="(item, key) in businessItems"
                  :key="key"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否备案">
              <el-select
                v-model="queryParams.isKeepOnRecord"
                clearable
                placeholder="请选择是否备案"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签">
              <el-select
                v-model="queryParams.label"
                multiple
                placeholder="请选择标签"
              >
                <el-option
                  v-for="(item, key) in labelList"
                  :key="key"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                style="width: 300px"
                clearable
                v-model="queryParams.keyWord"
                placeholder="名称/统一社会信用代码/营业场所/法定代表人/联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
            <div class="down-export">
              <el-button type="primary" @click="downloadTemp()"
                >模板下载</el-button
              >
              <el-button type="primary" @click="uploadVisible = true">导入</el-button>
              <el-button type="primary" plain @click="downLoad">导出</el-button>
            </div>
          </el-form>
        </div>
        <el-table
          v-loading="loading"
          style="width: 100%; margin-top: 10px"
          :data="tableData"
          border
          :header-cell-style="headerCellStyle"
        >
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          />
          <el-table-column prop="name" label="名称" align="center" />
          <el-table-column
            prop="socialCode"
            label="统一社会信用代码"
            align="center"
          />
          <el-table-column
            prop="residence"
            label="住所（营业场所）"
            align="center"
          />
          <el-table-column
            prop="isKeepOnRecord"
            label="是否备案"
            align="center"
          />
          <el-table-column
            prop="businessItems"
            label="涉及备案的经营项目"
            align="center"
          />
          <el-table-column prop="label" label="标签" align="center">
            <template slot="header">
              <div @click="setLabel">
                <span>标签</span>
                <i class="el-icon-s-tools"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.label }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="address6"
            label="操作"
            width="250"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 上传 -->
    <UploadFile url="/pensionInvolvingOldMarket/importTemplate" :uploadVisible.sync="uploadVisible" @updateList="searchList" />
    <setLabel
      v-if="setLabelVisible"
      :isVisible.sync="setLabelVisible"
      @closeDialog="setLabelVisible = false"
    />
  </div>
</template>

<script>
import UploadFile from '@/components/uploadFile'
import config from "@/api/config";
import { downloadFile, downloadStream } from "@/utils/index";
import {
  downloadTemplate,
  pageList,
  getSomeData,
  exportTemplate,
} from "@/api/oldMarketPlayers";
import setLabel from "./components/setLabelD.vue";
export default {
  name: "index",
  components: {
    UploadFile,
    setLabel,
  },
  data() {
    return {
      uploadVisible: false,
      setLabelVisible: false,
      loading: false,
      tableData: [],
      queryParams: {
        //查询参数
        pageNum: 1,
        pageSize: 10,
        businessItems: "",
        isKeepOnRecord: "",
        label: "",
        keyWord: "", //关键字
      },
      total: 0,
      labelList: [],
      businessItems: [],
    };
  },
  mounted() {
    this.getList();
    this.getSomeData();
  },
  methods: {
    downloadTemp() {
      downloadTemplate({}).then((res) => {
        downloadStream(res, "涉老市场主体模板.xlsx");
      });
    },
    async downLoad() {
      const res = await exportTemplate(this.queryParams);
      downloadStream(res, "涉老市场主题表.xlsx");
    },
    // 下载传导出结束
    async getSomeData() {
      const res = await getSomeData();
      this.businessItems = res.data.business_items;
      this.labelList = res.data.label;
    },
    async getList() {
      this.loading = true;
      try {
        const res = await pageList(this.queryParams);
        this.loading = false;
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } catch (e) {
        this.loading = false;
      }
    },
    setLabel() {
      this.setLabelVisible = true;
    },
    //分页序号
    count(index) {
      return (
        (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
      );
    },
    toDetail(row) {
      this.$router.push({
        path: "/institutionalInformation/oldMarketPlayers/oldMarketPlayersDetail",
        query: { id: row.id },
      });
    },
    searchList() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize;
      this.queryParams.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  min-height: calc(100% - 10px);
}
.search-table-box {
  .down-export {
    float: right;
    margin-bottom: 20px;
    display: flex;
    button {
      margin-left: 10px;
    }
    button:first-child {
      margin: 0 10px;
    }
  }
}
/deep/ .upload-demo {
    text-align: center;
}
</style>
